<!--  -->
<template>
  <div>
    <el-container style="height: 100vh;">
      <el-header>
        <Header></Header>
      </el-header>
      <el-container style="background: #efefef;">
        <el-aside width="200px">
          <SideBar></SideBar>
        </el-aside>
        <el-main>
          <div class="content">
            <transition name="move" mode="out-in">
              <!-- <keep-alive> -->
              <router-view></router-view>
              <!-- </keep-alive> -->
            </transition>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from './Header';
import SideBar from './SideBar.vue';
export default {
  name: 'FrameContainer',
  data() {
    return {};
  },

  components: { Header, SideBar },

  computed: {},

  mounted() {},

  watch: {},
};
</script>
<style lang="less">
aside.el-aside {
  width: 200px;
  overflow: hidden;
  ul.el-menu {
    width: 199px;
  }
}
.el-main {
  padding: 0;
}
</style>
<style lang="less" scoped>
.move-enter-active,
.move-leave-active {
  transition: opacity 1s;
}

.move-enter,
.move-leave {
  opacity: 0;
}
.content {
  height: calc(100vh - 60px);
  padding: 20px;
  background: #efefef;
}
</style>
