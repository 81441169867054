<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      router
      :unique-opened="true"
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <i :class="`iconfont icon-${item.icon}`"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <template slot="title">{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  @click="openNewPage(item.blankHref)"
                >
                  {{ threeItem.title }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                v-else
                :index="subItem.index"
                :key="subItem.index"
                @click="openNewPage(item.blankHref)"
              >
                {{ subItem.title }}
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index" @click="openNewPage(item.blankHref)">
            <i :class="`iconfont icon-${item.icon}`"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
// import bus from '../common/bus';
import menus from '@/data/menus';
export default {
  name: 'SideBar',
  data() {
    return {
      collapse: false,
      items: Number(this.$store.state.uid) === 3 ? menus.filter(ele => ele.level === 3) : menus,
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path;
    },
  },
  created() {
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    // bus.$on('collapse', msg => {
    //   this.collapse = msg;
    // });
  },
  mounted() {
    // this.$Enum.backresource()
  },
  methods: {
    openNewPage(href) {
      if (href) window.open(href);
    },
  },
};
</script>

<style lang="less">
.sidebar {
  height: calc(100vh - 60px);
  overflow-y: auto;
  background: #fff;
  .sidebar-el-menu {
    .iconfont {
      font-size: 18px;
    }
    background: #fff;
    li {
      color: @color-text-regular;
    }
    .el-menu-item,
    .el-submenu__title {
      height: 50px;
      line-height: 50px;
      &.is-active:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background: #6f89e4;
      }
      span {
        margin-left: 10px;
      }
      i {
        color: @color-text-regular;
      }
      .el-icon-arrow-down {
        font-weight: 600;
      }
    }
    .el-submenu__title:hover,
    .el-menu-item:focus,
    .el-menu-item.is-active,
    .el-menu-item:hover {
      background: #f4f7ff;
      color: @color-primary;
      i {
        color: inherit;
      }
    }
  }
  .el-menu {
    background: #fff;
    width: 100% !important;
  }
}
</style>
