module.exports = [
  // {
  //   icon: 'home',
  //   index: '/home',
  //   title: '首页',
  //   role: '',
  // },
  // {
  //   icon: 'tags',
  //   index: 'brand',
  //   title: '品牌选品管理',
  //   role: 'brand/',
  //   subs: [
  //     {
  //       index: '/brand/merchant-data/1',
  //       title: '品牌数据大脑',
  //     },
  //   ],
  // },
  {
    icon: 'home',
    index: '/',
    title: '首页',
    role: '',
  },
  // {
  //   icon: 'home',
  //   index: '/select-pool',
  //   title: '商家选品池',
  //   role: '',
  // },
];
